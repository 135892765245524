import "core-js/stable"
import "regenerator-runtime/runtime"
import "whatwg-fetch"

import Form from "components/church_center/form"
import SubscriptionsIndex from "components/church_center/subscriptions_index"
import { SelfHydratingHeader } from "@planningcenter/cc-nav"
import React from "react"
import ReactDOM from "react-dom"

window.React = React
window.ReactDOM = ReactDOM

window.Components = {
  Form,
  SelfHydratingHeader,
  Subscriptions: {
    Index: SubscriptionsIndex,
  },
}
