import React, { useEffect, useState } from "react"
import { bool } from "prop-types"

export function Loading() {
  const [step, setStep] = useState(0)
  useEffect(() => {
    const timeout = setTimeout(() => setStep((step + 1) % 4), 350)
    return () => {
      clearTimeout(timeout)
    }
  }, [step])

  Dot.propTypes = { visible: bool.isRequired }
  function Dot({ visible }) {
    const visibility = visible ? "visible" : "hidden"
    return <span style={{ visibility }}>.</span>
  }

  return (
    <div className="d-f ai-c c-tint4">
      <span className="d-f">
        Loading
        <Dot visible={step > 0} />
        <Dot visible={step > 1} />
        <Dot visible={step > 2} />
      </span>
    </div>
  )
}
