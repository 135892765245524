import React, { useCallback, useEffect, useState } from "react"
import { isEmpty } from "lodash"
import uuid from "uuid"
import log from "utils/log"
import Alert from "utils/alert"
import {
  CheckboxGroup,
  CheckboxProminent,
  DateInput,
  Description,
  ErrorMessages,
  Field,
  FieldGroup,
  FileInput,
  NumberInput,
  SectionHeader,
  Select,
  TextArea,
  TextInput,
} from "components/church_center/ui"
import Icon from "components/church_center/cc_external_icon"
import alertIcons from "components/church_center/alert_icons"
import { SETTINGS } from "components/forms/fields/household_fields"
import { CountrySelect } from "components/church_center/country_select"
import api from "utils/church_center_api"
import { AutocompleteAddressInput } from "./autocomplete_address_input"

const useValueNullificationOnUnmount = ({ onChange, id }) => {
  useEffect(() => {
    return () => {
      onChange(null, id)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

export const HouseholdField = ({
  id,
  values,
  errors = {},
  onChange,
  attributes,
  phoneTypes,
  maritalStatusOptions,
  genderOptions,
  grades,
  schoolOptions,
}) => {
  useValueNullificationOnUnmount({ onChange, id })

  const [adult, setAdult] = useState(values?.adult)
  const [children, setChildren] = useState(values?.children || [])

  const onAdultChange = useCallback(adult => setAdult(adult), [])
  const onChildrenChange = useCallback(
    child =>
      setChildren(prevChildren =>
        prevChildren.map(c => (c.uuid === child.uuid ? child : c))
      ),
    []
  )

  useEffect(
    () => onChange({ adult, children }, id),
    [onChange, adult, children, id]
  )

  return (
    <Field id={id} {...attributes}>
      <Description description={attributes.description} />
      {adult && (
        <AdultField
          values={adult}
          onChange={onAdultChange}
          onRemove={() => setAdult(null)}
          errors={errors}
          attributes={attributes}
          phoneTypes={phoneTypes}
          maritalStatusOptions={maritalStatusOptions}
          genderOptions={genderOptions}
        />
      )}
      {!adult && (
        <button
          type="button"
          className="btn secondary-btn minor-btn mr-1"
          onClick={() =>
            setAdult({
              first_name: "",
              last_name: "",
              email_address: "",
              uuid: uuid.v4(),
            })
          }
        >
          + Add adult
        </button>
      )}
      {children?.map(child => (
        <ChildField
          key={child.uuid}
          uuid={child.uuid}
          values={child}
          onChange={onChildrenChange}
          onRemove={uuid => setChildren(children.filter(c => c.uuid !== uuid))}
          errors={errors}
          attributes={attributes}
          grades={grades}
          schoolOptions={schoolOptions}
          genderOptions={genderOptions}
        />
      ))}
      <button
        type="button"
        className="btn secondary-btn minor-btn"
        onClick={() => {
          if (children.length >= 10) {
            Alert.warn({
              imageUrl: alertIcons.warning,
              title: "Uh oh!",
              text: "You can only add up to ten children.",
            })
          } else {
            setChildren([
              ...children,
              { first_name: "", last_name: "", uuid: uuid.v4() },
            ])
          }
        }}
      >
        + Add child
      </button>
    </Field>
  )
}

const AdultField = ({
  values,
  onChange,
  onRemove,
  errors,
  attributes,
  phoneTypes,
  maritalStatusOptions,
  genderOptions,
}) => {
  const [value, setValue] = useState(values)

  useEffect(() => onChange(value), [onChange, value])

  const handleChange = (setting, v) => {
    setValue(prevValue => {
      return { ...prevValue, [setting]: v }
    })
  }

  const handlePhoneNumberChange = useCallback(v => {
    setValue(prevValue => {
      return {
        ...prevValue,
        phone_number: { ...prevValue.phone_number, ...v },
      }
    })
  }, [])

  const handleGenderChange = useCallback(v => {
    setValue(prevValue => {
      return { ...prevValue, gender: v }
    })
  }, [])

  const handleMaritalStatusChange = useCallback(v => {
    setValue(prevValue => {
      return { ...prevValue, marital_status: v }
    })
  }, [])

  return (
    <div className="mb-2 py-2 action-drawer">
      <h3 className="d-f jc-sb">
        <span>Adult</span>
        <button
          type="button"
          className="text-btn icon c-ruby mr-1 fs-3 d-f ai-c"
          onClick={onRemove}
        >
          <Icon
            symbol="general#outlined-minus-circle"
            title="Remove adult from household"
          />
          <span className="fs-4 ml-4p">Remove</span>
        </button>
      </h3>
      <Field label="Name" id={value.uuid} field_type="adult_name" required>
        <div className="d-f@md f_1 d-b@iframe">
          <div>
            <TextInput
              id={`adult_name_${value.uuid}`}
              placeholder="First name"
              errors={errors["adult.first_name"]}
              onChange={e => handleChange("first_name", e.target.value)}
              required
            />
            <ErrorMessages
              errors={
                errors["adult.first_name"] && {
                  "first name": errors["adult.first_name"],
                }
              }
              className="mt-1"
            />
          </div>
          <div className="ml-0 ml-1@md ml-0@iframe mt-1 mt-0@md mt-1@iframe">
            <label className="screen-reader-text" htmlFor="last_name">
              Last name
            </label>
            <TextInput
              placeholder="Last name"
              errors={errors["adult.last_name"]}
              onChange={e => handleChange("last_name", e.target.value)}
              required
            />
            <ErrorMessages
              errors={
                errors["adult.last_name"] && {
                  "last name": errors["adult.last_name"],
                }
              }
              className="mt-1"
            />
          </div>
        </div>
      </Field>
      <Field
        id={value.uuid}
        field_type="adult_email_address"
        label="Email address"
        required
      >
        <TextInput
          type="email"
          id={`adult_email_address_${value.uuid}`}
          placeholder="name@example.com"
          errors={errors["adult.email_address"]}
          onChange={e => handleChange("email_address", e.target.value)}
          required
        />
        <ErrorMessages
          errors={
            errors["adult.email_address"] && {
              "email address": errors["adult.email_address"],
            }
          }
          className="mt-1"
        />
      </Field>
      {Object.keys(SETTINGS.adults)
        .filter(setting => attributes.settings.adults?.[setting]?.collect)
        .map(setting => {
          const householdFieldAttributes = {
            label: SETTINGS.adults[setting],
            description: "",
            required: attributes.settings.adults[setting].required,
            field_type: `household_adult_${setting}`,
          }
          const householdFieldErrors = errors[`adult.${setting}`] && {
            [setting]: errors[`adult.${setting}`],
          }

          switch (setting) {
            case "phone_number": {
              const phoneNumberErrors = {}
              if (errors["adult.phone_number.location"]) {
                phoneNumberErrors["location"] =
                  errors["adult.phone_number.location"]
              }
              if (errors["adult.phone_number.number"]) {
                phoneNumberErrors["number"] =
                  errors["adult.phone_number.number"]
              }
              return (
                <PhoneNumberField
                  key={`${setting}_${value.uuid}`}
                  id={value.uuid}
                  errors={{ ...householdFieldErrors, ...phoneNumberErrors }}
                  attributes={householdFieldAttributes}
                  onChange={handlePhoneNumberChange}
                  phoneTypes={phoneTypes}
                />
              )
            }
            case "gender":
              return (
                <GenderField
                  key={`${setting}_${value.uuid}`}
                  id={value.uuid}
                  attributes={householdFieldAttributes}
                  errors={householdFieldErrors}
                  onChange={handleGenderChange}
                  autoComplete="off"
                  options={genderOptions}
                />
              )
            case "birthday":
              return (
                <DateField
                  key={`${setting}_${value.uuid}`}
                  id={value.uuid}
                  errors={householdFieldErrors}
                  attributes={householdFieldAttributes}
                  onChange={v => handleChange(setting, v)}
                  yearRange={"c-120Y:c"}
                  className="date-field__input"
                />
              )
            case "marital_status":
              return (
                <DropdownField
                  key={`${setting}_${value.uuid}`}
                  id={value.uuid}
                  errors={householdFieldErrors}
                  attributes={householdFieldAttributes}
                  onChange={handleMaritalStatusChange}
                  options={maritalStatusOptions}
                />
              )
            case "anniversary":
              return (
                <DateField
                  key={`${setting}_${value.uuid}`}
                  id={value.uuid}
                  errors={householdFieldErrors}
                  attributes={householdFieldAttributes}
                  onChange={v => handleChange(setting, v)}
                  yearRange={"-100:+20"}
                  className="date-field__input"
                />
              )
            default:
              log.error("setting isn't handled:", setting)
              return null
          }
        })}
    </div>
  )
}

const ChildField = ({
  uuid,
  values,
  onChange,
  onRemove,
  errors,
  attributes,
  grades,
  schoolOptions,
  genderOptions,
}) => {
  const [value, setValue] = useState(values)

  useEffect(() => onChange(value, uuid), [uuid, onChange, value])

  const handleChange = (setting, v) => {
    setValue(prevValue => {
      return { ...prevValue, [setting]: v }
    })
  }

  const handleGenderChange = useCallback(v => {
    setValue(prevValue => {
      return { ...prevValue, gender: v }
    })
  }, [])

  const handleGradeChange = useCallback(v => {
    setValue(prevValue => {
      return { ...prevValue, grade: v }
    })
  }, [])

  const handleSchoolChange = useCallback(v => {
    setValue(prevValue => {
      return { ...prevValue, school: v }
    })
  }, [])

  return (
    <div className="my-2 py-2 action-drawer">
      <h3 className="d-f jc-sb">
        Child
        <button
          type="button"
          className="text-btn icon c-ruby mr-4p fs-3 d-f ai-c"
          onClick={() => onRemove(uuid)}
        >
          <Icon
            symbol="general#outlined-minus-circle"
            title="Remove child from household"
          />
          <span className="fs-4 ml-1">Remove</span>
        </button>
      </h3>
      <Field label="Name" id={uuid} field_type="child_name" required>
        <div className="d-f@md f_1 d-b@iframe">
          <div>
            <TextInput
              id={`child_name_${uuid}`}
              placeholder="First name"
              errors={errors[`child.${uuid}.first_name`]}
              onChange={e => handleChange("first_name", e.target.value)}
              required
            />
            <ErrorMessages
              errors={
                errors[`child.${uuid}.first_name`] && {
                  "first name": errors[`child.${uuid}.first_name`],
                }
              }
              className="mt-1"
            />
          </div>
          <div className="ml-0 ml-1@md ml-0@iframe mt-1 mt-0@md mt-1@iframe">
            <label className="screen-reader-text" htmlFor="last_name">
              Last name
            </label>
            <TextInput
              placeholder="Last name"
              errors={errors[`child.${uuid}.last_name`]}
              onChange={e => handleChange("last_name", e.target.value)}
              required
            />
            <ErrorMessages
              errors={
                errors[`child.${uuid}.last_name`] && {
                  "last name": errors[`child.${uuid}.last_name`],
                }
              }
              className="mt-1"
            />
          </div>
        </div>
      </Field>
      {Object.keys(SETTINGS.children)
        .filter(setting => attributes.settings.children?.[setting]?.collect)
        .map(setting => {
          const householdFieldAttributes = {
            label: SETTINGS.children[setting],
            description: "",
            required: attributes.settings.children[setting].required,
            field_type: `household_child_${setting}`,
          }
          const householdFieldErrors = errors[`child.${uuid}.${setting}`] && {
            [setting]: errors[`child.${uuid}.${setting}`],
          }

          switch (setting) {
            case "gender":
              return (
                <GenderField
                  key={`${setting}_${uuid}`}
                  id={uuid}
                  attributes={householdFieldAttributes}
                  errors={householdFieldErrors}
                  onChange={handleGenderChange}
                  autoComplete="off"
                  options={genderOptions}
                />
              )
            case "birthday":
              return (
                <DateField
                  key={`${setting}_${uuid}`}
                  id={uuid}
                  errors={householdFieldErrors}
                  attributes={householdFieldAttributes}
                  onChange={v => handleChange(setting, v)}
                  yearRange={"c-120Y:c"}
                  className="date-field__input"
                />
              )
            case "grade":
              return (
                <DropdownField
                  key={`${setting}_${uuid}`}
                  id={uuid}
                  errors={householdFieldErrors}
                  attributes={householdFieldAttributes}
                  onChange={handleGradeChange}
                  options={grades.map(({ key, value }) => {
                    return (
                      <option key={key} value={key}>
                        {value}
                      </option>
                    )
                  })}
                />
              )
            case "school":
              return (
                <DropdownField
                  key={`${setting}_${uuid}`}
                  id={uuid}
                  errors={householdFieldErrors}
                  attributes={householdFieldAttributes}
                  onChange={handleSchoolChange}
                  options={schoolOptions}
                />
              )
            case "medical":
              return (
                <TextAreaField
                  key={`${setting}_${uuid}`}
                  id={uuid}
                  errors={householdFieldErrors}
                  attributes={householdFieldAttributes}
                  onChange={v => handleChange(setting, v)}
                  fieldNote="Medical notes may be printed on name tags."
                />
              )
            default:
              log.error("setting isn't handled:", setting)
              return null
          }
        })}
    </div>
  )
}

export const TextInputField = ({ onChange, attributes, id, errors }) => {
  const { placeholder, description, field_type: fieldType } = attributes

  useValueNullificationOnUnmount({ onChange, id })

  return (
    <Field id={id} key={id} errors={errors} {...attributes}>
      <Description description={description} />
      <TextInput
        errors={errors}
        id={`${fieldType}_${id}`}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value, id)}
      />
    </Field>
  )
}

export const CheckboxesField = ({
  onChange,
  attributes,
  id,
  options,
  otherOption,
  errors,
  ...props
}) => {
  useValueNullificationOnUnmount({ onChange, id })

  const { description } = attributes

  return (
    <Field {...props} id={id} key={id} errors={errors} {...attributes}>
      <Description description={description} />
      <CheckboxGroup
        id={id}
        errors={errors}
        options={options}
        hasOtherOption={!!otherOption}
        onChange={selected => onChange(selected, id)}
      />
    </Field>
  )
}

export const PhoneNumberField = ({
  id,
  attributes,
  onChange,
  phoneTypes,
  errors,
  ...props
}) => {
  const { description } = attributes

  useValueNullificationOnUnmount({ onChange, id })

  return (
    <Field {...props} id={id} key={id} errors={errors} {...attributes}>
      <Description description={description} />
      <FieldGroup className="d-f fd-c fd-r@md d-b@iframe">
        <div>
          <label className="screen-reader-text" htmlFor={`phone_type_${id}`}>
            Phone type
          </label>
          <Select
            id={`phone_type_${id}`}
            defaultValue="Mobile"
            onChange={useCallback(
              location => onChange({ location }, id),
              [onChange, id]
            )}
            className={
              errors &&
              !isEmpty(errors.errors) &&
              Object.keys(errors.errors).includes("location")
                ? "form-field--error"
                : ""
            }
          >
            {phoneTypes.map(pt => (
              <option key={pt}>{pt}</option>
            ))}
          </Select>
        </div>
        <div className="f-1 ml-0 ml-1@md ml-0@iframe mt-1 mt-0@md mt-1@iframe">
          <TextInput
            className={
              errors &&
              !isEmpty(errors.errors) &&
              Object.keys(errors.errors).includes("number")
                ? "form-field--error"
                : ""
            }
            onChange={e => {
              const number = e.target.value
              onChange({ number }, id)
            }}
            id={`phone_number_${id}`}
            name={`phone_number_${id}`}
            type="tel"
            autoComplete="tel"
          />
        </div>
      </FieldGroup>
    </Field>
  )
}

export const TextAreaField = ({
  onChange,
  id,
  attributes,
  errors,
  ...props
}) => {
  const { placeholder, description, field_type: fieldType } = attributes

  useValueNullificationOnUnmount({ onChange, id })

  return (
    <Field {...props} id={id} key={id} errors={errors} {...attributes}>
      <Description description={description} />
      <TextArea
        errors={errors}
        id={`${fieldType}_${id}`}
        placeholder={placeholder}
        onChange={e => onChange(e.target.value, id)}
      />
    </Field>
  )
}

export const DropdownField = ({
  onChange,
  id,
  attributes,
  options,
  otherOption,
  errors,
  ...props
}) => {
  useValueNullificationOnUnmount({ onChange, id })

  const { description, field_type: fieldType } = attributes

  return (
    <Field {...props} id={id} key={id} errors={errors} {...attributes}>
      <Description description={description} />
      <Select
        errors={errors}
        id={`${fieldType}_${id}`}
        hasOtherOption={!!otherOption}
        onChange={useCallback(value => onChange(value, id), [id, onChange])}
      >
        <option />
        {options}
      </Select>
    </Field>
  )
}

export const AddressField = ({
  onChange,
  id,
  attributes,
  locationOptions,
  errors,
  value,
  countries,
  ...props
}) => {
  const [selectedCountry, setSelectedCountry] = useState()
  const { description } = attributes
  const { country_code } = value || {}

  useEffect(() => {
    // Fetch the address_fields for the current country_code
    if (country_code) {
      api.get(
        `/people/v2/countries/${country_code}`,
        {
          "fields[Country]": "address_fields",
        },
        resp => setSelectedCountry(resp.data)
      )
    }
  }, [country_code])

  useValueNullificationOnUnmount({ onChange, id })

  const getPlaceholder = fieldName => {
    if (selectedCountry) {
      const fieldNames = selectedCountry.attributes.address_fields
      switch (fieldName) {
        case "street_line_1":
          return fieldNames[0][0].label
        case "street_line_2":
          return fieldNames[1][0].label
        case "city":
          return fieldNames[2][0].label
        case "state":
          return fieldNames[2][1].label
        case "zip":
          return fieldNames[2][2].label
      }
    }
  }

  return (
    <Field {...props} id={id} key={id} errors={errors} {...attributes}>
      <Description description={description} />
      <FieldGroup>
        <div className="d-f mb-1 fd-c fd-r@md d-b@iframe">
          <Select
            id={`address_${id}`}
            onChange={useCallback(
              location => onChange({ location }, id),
              [id, onChange]
            )}
            defaultValue="Home"
            className={
              errors &&
              !isEmpty(errors.errors) &&
              Object.keys(errors.errors).includes("location")
                ? "form-field--error"
                : ""
            }
          >
            {locationOptions}
          </Select>

          <div className="d-f f-1 ai-c">
            <div className="ml-0 ml-1@md ml-0@iframe mt-1 mt-0@md mt-1@iframe">
              <label className="screen-reader-text" htmlFor="country_code">
                Country
              </label>
              <CountrySelect
                options={countries}
                id="country_code"
                value={country_code}
                onChange={countryCode =>
                  onChange({ country_code: countryCode }, id)
                }
              />
            </div>

            <AutocompleteAddressInput
              placeholder={getPlaceholder("street_line_1") || "Street Address"}
              address={value.street || ""}
              updateAttribute={street => {
                onChange({ street }, id)
              }}
              onAutocompleteSelect={({
                city,
                country_code,
                state,
                street_line_1: street,
                street_line_2: street2,
                zip,
              }) => {
                onChange(
                  { city, country_code, state, street, street2, zip },
                  id
                )
              }}
            />
          </div>
        </div>
        <label className="screen-reader-text" htmlFor="address_2">
          Apt/unit/box (optional)
        </label>
        <TextInput
          className="mb-1"
          id="address_2"
          errors={errors}
          placeholder={
            getPlaceholder("street_line_2") || "Apt/unit/box (optional)"
          }
          autoComplete="address-line2"
          onChange={e => {
            const street2 = e.target.value
            onChange({ street2 }, id)
          }}
          value={value.street2 || ""}
        />
        <div className="d-f mb-1 fd-c fd-r@md d-b@iframe">
          <label className="screen-reader-text" htmlFor="city">
            City
          </label>
          <TextInput
            className="f-1"
            id="city"
            errors={errors}
            placeholder={getPlaceholder("city") || "City"}
            onChange={e => {
              const city = e.target.value
              onChange({ city }, id)
            }}
            autoComplete="address-level2"
            value={value.city || ""}
          />
          <label className="screen-reader-text" htmlFor="state">
            State
          </label>
          <TextInput
            className="f-1 ml-0 ml-1@md ml-0@iframe mt-1 mt-0@md mt-1@iframe"
            id="state"
            errors={errors}
            placeholder={getPlaceholder("state") || "State"}
            onChange={e => {
              const state = e.target.value
              onChange({ state }, id)
            }}
            autoComplete="address-level1"
            value={value.state || ""}
          />
          <label className="screen-reader-text" htmlFor="postal_code">
            Postal code
          </label>
          <TextInput
            className="f-1 ml-0 ml-1@md ml-0@iframe mt-1 mt-0@md mt-1@iframe"
            id="postal_code"
            errors={errors}
            placeholder={getPlaceholder("zip") || "Postal code"}
            onChange={e => {
              const zip = e.target.value
              onChange({ zip }, id)
            }}
            autoComplete="postal-code"
            value={value.zip || ""}
          />
        </div>
      </FieldGroup>
    </Field>
  )
}

export const GenderField = props => {
  useValueNullificationOnUnmount({ onChange: props.onChange, id: props.id })
  return <DropdownField autoComplete="sex" {...props} />
}

export const WorkflowCheckboxField = ({
  onChange,
  id,
  attributes,
  options,
  errors,
  ...props
}) => {
  const { label, description } = attributes
  const option = options[0]

  useValueNullificationOnUnmount({ onChange, id })

  return (
    <Field
      {...props}
      id={id}
      key={id}
      errors={errors}
      {...attributes}
      noLabel={true}
    >
      <div>
        <FieldGroup>
          <CheckboxProminent
            errors={errors}
            key={option.id}
            label={label}
            id={`checkbox_${option.id}`}
            required={attributes.required}
            onChange={e => onChange(e.target.checked && option.id, id)}
          />
        </FieldGroup>
      </div>
      <Description description={description} />
    </Field>
  )
}

export const WorkflowCheckboxesField = ({
  onChange,
  id,
  attributes,
  options,
  errors,
  otherOption,
  ...props
}) => {
  useValueNullificationOnUnmount({ onChange, id })

  const { description } = attributes

  return (
    <Field
      {...props}
      id={id}
      key={id}
      errors={errors}
      {...attributes}
      noLabel={false}
    >
      <Description description={description} />
      <CheckboxGroup
        id={id}
        errors={errors}
        options={options}
        onChange={value => onChange(value, id)}
        hasOtherOption={!!otherOption.data}
      />
    </Field>
  )
}

export const WorkflowDropdownField = ({
  onChange,
  id,
  attributes,
  options,
  errors,
  otherOption,
  ...props
}) => {
  useValueNullificationOnUnmount({ onChange, id })

  const { description } = attributes

  return (
    <Field
      {...props}
      id={id}
      key={id}
      errors={errors}
      {...attributes}
      noLabel={false}
    >
      <Description description={description} />
      <Select
        errors={errors}
        hasOtherOption={!!otherOption.data}
        id={`workflow_dropdown_${id}`}
        onChange={useCallback(value => onChange(value, id), [id, onChange])}
      >
        <option />
        {options
          .sort((a, b) => a.attributes.sequence - b.attributes.sequence)
          .map(o => (
            <option key={o.id} value={o.id}>
              {o.attributes.label}
            </option>
          ))}
      </Select>
    </Field>
  )
}

export const DateField = ({ onChange, id, attributes, errors, ...props }) => {
  const { description, field_type: fieldType } = attributes

  useValueNullificationOnUnmount({ onChange, id })

  return (
    <Field id={id} key={id} errors={errors} {...attributes}>
      <Description description={description} />
      <DateInput
        errors={errors}
        id={`${fieldType}_${id}`}
        onChange={e => onChange(e.target.value, id)}
        {...props}
      />
    </Field>
  )
}

export const HeadingField = ({ id, attributes, ...props }) => {
  return (
    <Field {...props} id={id} key={id}>
      <SectionHeader key={id} {...attributes} />
    </Field>
  )
}

export const YesNoField = ({ onChange, id, attributes, errors, ...props }) => {
  const { description } = attributes

  useValueNullificationOnUnmount({ onChange, id })

  return (
    <Field
      {...props}
      id={id}
      key={id}
      errors={errors}
      {...attributes}
      noLabel={true}
    >
      <div>
        <CheckboxProminent
          errors={errors}
          key={id}
          label={attributes.label}
          id={`checkbox_${attributes.label}_${id}`}
          required={attributes.required}
          onChange={e => onChange(e.target.checked, id)}
        />
      </div>
      <Description description={description} />
    </Field>
  )
}

export const NumberField = ({
  id,
  attributes,
  onChange,
  value,
  errors,
  ...props
}) => {
  const {
    description,
    settings: { min, max },
  } = attributes

  useValueNullificationOnUnmount({ onChange, id })

  return (
    <Field
      {...props}
      id={id}
      key={id}
      errors={errors}
      help={
        <p className="mb-0">
          {min && `min: ${min}`}
          {min && max && " / "}
          {max && `max: ${max}`}
        </p>
      }
      {...attributes}
    >
      <Description description={description} />
      <NumberInput
        errors={errors}
        key={id}
        value={isNaN(parseInt(value)) ? "" : parseInt(value)}
        id={`number_${id}`}
        onChange={value => onChange(value.toString(), id)}
        autoWidth={false}
        type="number"
      />
    </Field>
  )
}

export const FileInputField = ({
  id,
  attributes,
  errors,
  multiFileInput,
  onAddFile,
  onRemoveFile,
  props,
  maxTotalFileSize,
  totalFileSizeExceedsLimit,
}) => {
  const [uploadErrors, setUploadErrors] = useState(errors)

  useEffect(() => {
    setUploadErrors(errors)
  }, [errors])

  return (
    <Field {...props} id={id} key={id} {...attributes}>
      <Description description={attributes.description} />
      <FileInput
        key={id}
        id={id}
        blockList={attributes.block_list}
        multiFileInput={multiFileInput}
        onAddFile={onAddFile}
        onRemoveFile={onRemoveFile}
        onUploadErrors={setUploadErrors}
        maxTotalFileSize={maxTotalFileSize}
        totalFileSizeExceedsLimit={totalFileSizeExceedsLimit}
      />
      <ErrorMessages
        errors={
          uploadErrors["attachments"] && {
            Upload: uploadErrors["attachments"],
          }
        }
      />
    </Field>
  )
}
