import React, { useRef, useState } from "react"
import Recaptcha from "react-google-recaptcha"

const STATES = Object.freeze({
  DISABLED: "disabled",
  FAILED: "failed",
  EXPIRED: "expired",
  SUCCESS: "success",
  WAITING: "waiting",
})

export default function RecaptchaForm({
  recaptchaEnabled,
  recaptchaSiteKey,
  children,
  ...props
}) {
  const recaptchaRef = useRef()
  const [failed, setFailed] = useState(false)
  const [expired, setExpired] = useState(false)
  const [token, setToken] = useState()

  let state
  if (!recaptchaEnabled) state = STATES.DISABLED
  else if (failed) state = STATES.FAILED
  else if (expired) state = STATES.EXPIRED
  else if (token) state = STATES.SUCCESS
  else state = STATES.WAITING

  function handleError() {
    setFailed(true)
    setToken(null)
  }

  function handleExpired() {
    setExpired(true)
    setToken(null)
  }

  function wrapSubmit(submit) {
    async function wrappedSubmit(...args) {
      if (!recaptchaEnabled) return submit(...args)

      setToken(null)
      recaptchaRef.current.reset()

      let newToken
      try {
        newToken = await recaptchaRef.current.executeAsync()
        if (!newToken) {
          throw new Error()
        } else {
          setFailed(false)
          setExpired(false)
          setToken(newToken)
        }
      } catch (_error) {
        setFailed(true)
        return
      }
      return submit(...args)
    }
    return wrappedSubmit
  }

  const wrappedForm = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...props,
        wrapSubmit,
        recaptchaState: state,
        recaptchaToken: token,
      })
    }
    return child
  })

  return (
    <>
      {wrappedForm}
      {recaptchaEnabled && (
        <>
          <Recaptcha
            sitekey={recaptchaSiteKey}
            size="invisible"
            ref={recaptchaRef}
            onErrored={handleError}
            onExpired={handleExpired}
          />
          <RecaptchaBadge recaptchaState={state} />
        </>
      )}
    </>
  )
}

const RecaptchaBadge = ({ recaptchaState }) => {
  if (recaptchaState === STATES.DISABLED) return null

  return (
    <>
      <p className="fs-5 c-tint2 ta-c mt-2">
        This site is protected by reCAPTCHA and the Google{" "}
        <a className="c-tint3 d-ib" href="https://policies.google.com/privacy">
          Privacy Policy
        </a>{" "}
        and{" "}
        <a className="c-tint3 d-ib" href="https://policies.google.com/terms">
          Terms of Service
        </a>{" "}
        apply.
      </p>
      {recaptchaState === STATES.FAILED && (
        <p className="fs-5 c-tint2 ta-c">Unable to submit at this time.</p>
      )}
    </>
  )
}
