import { sessionApiClient } from "@planningcenter/cc-api-client"
import { buildSubdomain } from "@planningcenter/cc-url"

const apiBase = buildSubdomain("api")

export default class API {
  static get(path, params = {}, callback) {
    sessionApiClient.get(path, params).then(resp => callback && callback(resp))
  }

  static getAll(path, params = {}, callback, onComplete) {
    API.get(path, params, resp => {
      if (callback) {
        callback(resp)
      }

      if (resp.links && resp.links.next) {
        const path = resp.links.next.replace(apiBase, "")
        API.getAll(path, {}, callback, onComplete)
      } else {
        if (onComplete) {
          onComplete()
        }
      }
    })
  }

  static post(path, params = {}, callback) {
    sessionApiClient
      .post(path, params)
      .then(resp => callback && callback(resp))
      .catch(resp => callback && callback(resp))
  }
}
