import warningIcon from "@planningcenter/icons/svg/general/exclamation-triangle.svg"
import infoIcon from "@planningcenter/icons/svg/general/info-circle.svg"
import errorIcon from "@planningcenter/icons/svg/general/x-circle.svg"
import successIcon from "@planningcenter/icons/svg/general/check-circle.svg"

const prefix = "/people"
const alertIcons = {
  warning: `${prefix}${warningIcon}`,
  info: `${prefix}${infoIcon}`,
  error: `${prefix}${errorIcon}`,
  success: `${prefix}${successIcon}`,
}

export default alertIcons
