import React, { useState } from "react"

function SubscriptionsIndex({
  organizationName,
  emailAddress,
  submitSubscriptionsPath,
  messageTypeInformation,
}) {
  const [fullDetailsMessageTypes, setFullDetailsMessageTypes] = useState([])

  function displayFullDetails(messageType) {
    setFullDetailsMessageTypes([...fullDetailsMessageTypes, messageType])
  }

  function displayLessDetails(messageType) {
    setFullDetailsMessageTypes([
      fullDetailsMessageTypes.filter(m => m !== messageType),
    ])
  }

  return (
    <div>
      <h1 className="mt-3">Email subscriptions for {organizationName}</h1>
      <p>Select the emails you want to receive at {emailAddress}</p>

      <form action={submitSubscriptionsPath} method="post">
        {Object.entries(messageTypeInformation).map(
          ([messageType, { subscribed, short_description, details }]) => (
            <div key={messageType} className="mt-3">
              <input
                type="checkbox"
                name="message_types[]"
                value={messageType}
                defaultChecked={subscribed}
                className="large-checkbox checkbox"
                id={messageType}
              />
              <label
                htmlFor={messageType}
                className="large-checkbox-label checkbox-label pl-4"
              >
                <strong>{short_description}</strong>
              </label>
              {fullDetailsMessageTypes.includes(messageType) ? (
                <>
                  <div
                    key={messageType}
                    className="ml-4"
                    style={{
                      color: "#9e9e9e",
                    }}
                  >
                    {details}{" "}
                    <a
                      onClick={() => {
                        displayLessDetails(messageType)
                      }}
                    >
                      Read less
                    </a>
                  </div>
                </>
              ) : (
                <>
                  <div
                    key={messageType}
                    className="ml-4"
                    style={{
                      color: "#9e9e9e",
                    }}
                  >
                    {details.substring(0, 160)}...{" "}
                    <a
                      onClick={() => {
                        displayFullDetails(messageType)
                      }}
                    >
                      Read more
                    </a>
                  </div>
                </>
              )}
            </div>
          )
        )}
        <div className="my-4 ta-c">
          <button type="submit" className="btn">
            Save
          </button>
        </div>
      </form>
    </div>
  )
}

export default SubscriptionsIndex
